.commentbox {
  min-height: 400px;
  height: 600px;
  max-height: none;
  margin-top: 2rem;
}

iframe {
  height: 100% !important;
}

.form-container {
  z-index: 9999;
}
