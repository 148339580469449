.intellectual-outputs-item__container {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: min-content;
  align-items: center;
  min-height: 156px;
  padding: 1rem;
  border-radius: 7px;
  transition: transform 0.3s ease-in-out; /* Afegir una transició suau per a la transformació */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Afegir una ombra lleugera a la part inferior */
}
.intellectual-outputs-item__container:hover {
  transform: scale(1.1);
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Afegir una ombra lleugera a la part inferior */
}
.intellectual-outputs-item__title {
  color: #4d6065;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  margin: 0;
}

.intellectual-outputs-item__text {
  color: #4d6065;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin: 0;
  text-align: center;
}

.intellectuals-outputs-item__container__wrapper {
  margin: 0;
  padding: 0;
  text-decoration: none;
  width: 24%;
}

@media screen and (max-width: 768px) {
  .intellectuals-outputs-item__container__wrapper {
    width: calc(100% - 2rem);
  }
}
