.intellectual-outputs__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4rem;
  gap: 1.5rem;
  background: linear-gradient(90deg, #1d3561 0%, #87cdce 50%, #fbfdfd 100%);
}

.intellectual-outputs__title {
  color: #9ea8be;
  text-transform: uppercase;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 0;
}

.intellectual-outputs__item-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2%;
  row-gap: 2rem;
}

@media screen and (max-width: 768px) {
  .intellectual-outputs__item-wrapper {
    justify-content: center;
  }
}
