.unfoldingItem__container {
  border: 1px solid #e2e2e2;
  background: #f4f4f4;
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.3);
  padding: 1rem 0.75rem;
  width: calc(100% - 1.5rem);
}

.unfoldingItem__container:hover {
  cursor: pointer;
}
.clicked {
  background: white;
}
.unfoldingItem__wrapper-title {
  color: #626262;
  font-family: Arvo, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 175% */
}
.unfoldingItem__wrapper {
  display: flex;
  justify-content: space-between;
}
.unfoldingItem__container p {
  color: #626262;
  font-family: Arvo, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 175% */
}

.unfoldingItem__icon {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: #d9d9d9;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  line-height: 0;
  font-size: 12px;
  font-weight: bold;
}

.unfoldingItem__teacher-name {
  color: #626262;
  font-family: Arvo;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  line-height: 28px; /* 175% */
}

.unfolding-item__item-container {
  width: 50%;
  border: 1px solid gray;
  padding: 1rem;
  align-self: center;
}

.unfolding-item__item-container:hover {
  cursor: auto;
}

@media screen and (max-width: 700px) {
  .unfolding-item__item-container {
    width: calc(100% - 2rem);
  }
}
