.button__container {
  width: max-content;
  padding: 5px 10px;
  border-radius: 4px;
}
.button__container span {
  font-family: Arvo, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}

.button__container:hover {
  cursor: pointer;
}
