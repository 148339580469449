.blog-card__container {
  width: 27%;
  min-height: 300px;
  max-height: 600px;
  overflow: hidden;
  border-radius: 6px 6px 6px 6px;
  box-shadow: 0px 10px 24px 6px rgba(0, 0, 0, 0.06);
}

.blog-card__container img {
  object-fit: cover;
  width: 100%;
}

.blog-card__text-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 1rem;
}

.blog-card__text-wrapper h3 {
  line-height: 1.3em !important;
  font-size: 18px;
  font-family: "Arvo", Georgia, "Times New Roman", serif;
  font-size: 26px;
  color: #333;
  padding-bottom: 10px;
  line-height: 1em;
  font-weight: 500;
  margin: 0;
}

.blog-card__text-wrapper p {
  font-family: "Open Sans", Helvetica, Arial, Lucida, sans-serif;
  font-size: 15px;
  line-height: 1.8em;
  color: #486066 !important;
  margin: 0;
}

@media only screen and (max-width: 768px) {
  .blog-card__container {
    width: 100%;
    margin-top: 4rem;
  }
}
