.blog__container {
  margin-top: 100px;
}

.blog__cards-wrapper {
  padding: 3rem;
  padding-left: 4rem;
  padding-right: 4rem;
  display: flex;
  row-gap: 1rem;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .blog__cards-wrapper {
    padding: 1rem;
  }
}
