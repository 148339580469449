.blog-entry__container {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  padding-left: 10%;
  padding-right: 10%;
}

.blog-entry__container h2 {
  font-family: "Arvo", Georgia, "Times New Roman", serif;
  font-size: 30px;
  color: #333;
  padding-bottom: 10px;
  line-height: 1em;
  margin-bottom: 1rem;
  align-self: center;
}

.blog-entry__container img {
  width: 60%;
  object-fit: cover;
  align-self: center;
}

.blog-entry__container p {
  word-wrap: break-word;
  text-align: left;
  position: relative;
  font-family: "Arvo", Georgia, "Times New Roman", serif;
  line-height: 1.4;
}
