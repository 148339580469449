.contact-form {
  margin-top: 100px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  height: calc(70vh - 100px);
}

.contact-form__wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 20%;
}

.contact-form__input {
  font-size: 14px;
  padding: 16px;
  width: calc(100% - 32px);
  background-color: #eee;
  text-decoration: none;
  border: 1px solid #ccc; /* Assegura't que l'input tingui una vora inicial */
  border-radius: 4px; /* Especifica un radi de la vora inicial */
}

.contact-form__input::placeholder {
  color: black; /* Color del placeholder */
}

.contact-form__input:focus {
  border: none; /* Elimina la vora en focus */
  border-radius: 0; /* Elimina el radi de la vora en focus */
  outline: none; /* Elimina l'outline en focus */
}

/* Estils per al textarea */
.contact-form__textarea {
  font-size: 14px;
  padding: 16px;
  width: calc(100% - 32px);
  background-color: #eee;
  text-decoration: none;
  border: 1px solid #ccc; /* Assegura't que el textarea tingui una vora inicial */
  border-radius: 4px; /* Especifica un radi de la vora inicial */
}

.contact-form__textarea::placeholder {
  color: black; /* Color del placeholder */
}

.contact-form__textarea:focus {
  border: none; /* Elimina la vora en focus */
  border-radius: 0; /* Elimina el radi de la vora en focus */
  outline: none; /* Elimina l'outline en focus */
}
.contact-form__button {
  /* Eliminar estils per defecte */
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  color: inherit;
  text-align: inherit;
  cursor: pointer;

  /* Estils personalitzats */
  background-color: grey;
  color: #ccc;
  padding: 1rem 2rem;
  border-radius: 4px;
  font-size: 14px;
  text-transform: uppercase;
  transition: background-color 0.3s ease, color 0.3s ease;
  width: min-content;
}

.contact-form__bottom-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 90%;
}
