.teamMain__wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: calc(100% - 10rem);
  padding-left: 5rem;
  padding-right: 5rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

@media screen and (max-width: 768px) {
  .teamMain__wrapper {
    padding-left: 2rem;
    padding-right: 2rem;
    align-items: center;
    width: calc(100% - 4rem);
  }
}
