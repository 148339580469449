.footer__container {
  width: calc(100% - 25%);
  padding-left: 12.5%;
  padding-right: 12.5%;
  height: calc(20% - 10%);
  padding-top: 5%;
  padding-bottom: 5%;
  background-color: #e9e9e9;
  display: flex;
  justify-content: space-between;
}

.footer__button-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.footer__button-wrapper span {
  color: #5482b0;
  font-family: Arvo;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.footer-logo {
  object-fit: contain;
  max-width: 30%;
}
