.navbar__container {
  width: calc(100% - 25%);
  padding-left: 12.5%;
  padding-right: 12.5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  background-color: white;
  height: 60px;
  z-index: 150;
  transition: padding-top 0.5s, padding-bottom 0.5s; /* Transicions per padding */
}
.navbar__container.scrolled {
  padding-top: 8px;
  padding-bottom: 8px;
}

.navbar__container.not-scrolled {
  padding-top: 15px;
  padding-bottom: 15px;
}
.navbar__image {
  object-fit: contain;
  width: 10%;
}

.navbar__right-wrapper {
  display: flex;
  justify-content: space-between;
  width: 60%;
}

.navbar__right-wrapper a {
  color: #626262;
  font-family: Arvo, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
}

.navbar__right-wrapper a:hover {
  color: #1d3561;
  cursor: pointer;
}

.hamburger-button__container {
  width: calc(100% - 25%);
  padding-left: 12.5%;
  padding-right: 12.5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  background-color: white;
  height: 60px;
  z-index: 150;
  transition: padding-top 0.5s, padding-bottom 0.5s; /* Transicions per padding */
}

.menu-mobile__container {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50px;
  background-color: white;
  border: 1px solid white;
  width: 70%;
  padding: 1rem;
  border-radius: 3px 3px 3px 3px;
  right: 0;
  align-items: flex-end;
  min-height: 300px;
  justify-content: space-between;
  padding-right: 2rem;
  padding-top: 2rem;
}

.menu-mobile__container a {
  text-decoration: none;
  color: #1d3561;
  font-weight: bold;
  font-size: 20px;
}
