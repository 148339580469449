.firstSection__container {
  width: calc(100% - 25%);
  padding-left: 12.5%;
  padding-right: 12.5%;
  background: linear-gradient(90deg, #87cdce 0%, #fbfdfd 100%);
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-between;
}

.firstSection__wrapper {
  max-width: 50%;
  display: flex;
  flex-direction: column;
}

.firstSection__wrapper span {
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 600;
  font-size: 48px;
  color: #ffffff !important;
  line-height: 1.4em;
}

.firstSection__container p {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.first-section__logo {
  object-fit: contain;
  max-width: 55%;
}

@media screen and (max-width: 768px) {
  .firstSection__container {
    flex-direction: column-reverse;
    align-items: center;
  }
  .firstSection__wrapper {
    width: 100%;
    max-width: 100%;
  }
  .first-section__container p {
    margin-top: 0;
  }
}
