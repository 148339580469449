.team__container {
  background: linear-gradient(
    87deg,
    #e4eaf7 0.91%,
    #9cb1e1 50.39%,
    #3f69c6 98.89%
  );
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 12.5%;
  padding-right: 12.5%;
  width: calc(100% - 25%);
}

.team__title {
  color: #9ea8be;
  text-transform: uppercase;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.team__middle-wrapper p {
  color: #000;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.team__image-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* margin-top: 1rem; */
}

.team__logo {
  object-fit: contain;
  width: 20%;
}

@media screen and (max-width: 768px) {
  .team__image-wrapper {
    margin-top: 1rem;
  }
}
